import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../hooks/use-contenful";
import BlockLeftImage from "../components/BlockLeftImage";
import BlockRightImage from "../components/BlockRightImage";
import Block2ColumnsText from "../components/Block2ColumnsText";
import Block3ColumnsText from "../components/Block3ColumnsText";

import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import Accordion from "../components/Accordion";

const query = `
query 
{
    videoTemplate(id: "4MuVP0j5wEsPnTSEHJEaxZ") {
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json} 
      contentBlock7{json} 
      contentBlock8{json} 
      contentBlock9{json} 
      contentBlock10{json} 
      contentBlock11{json} 
      contentBlock12{json} 
      contentBlock13{json} 
  
    }
  
}
`;

function Transparencia() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className=" margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/transparencia">Transparencia</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout8  ">
        {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
      </div>

      <div>
        <BlockLeftImage
          src={data.videoTemplate.imageBlock2.url}
          alt={data.videoTemplate.imageBlock2.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock2.json
          )}
        ></BlockLeftImage>
        <div className="graybg">
          <BlockRightImage
            src="https://eventos.utpl.edu.ec/sites/default/files/images/600-x-800-web.jpg"
            content={
              <>
                {" "}
                <h2>Construcción del nuevo PEDI 2025-2030</h2>
                <h3>
                  {" "}
                  Conoce el proceso detrás del{" "}
                  <b>
                    {" "}
                    Plan Estratégico de Desarrollo Institucional (PEDI)
                    2025-2030
                  </b>
                </h3>
                <p>
                  Presentamos de manera clara y transparente cada etapa del
                  desarrollo del plan, los logros obtenidos y las actividades
                  clave que nos orientan hacia un futuro prometedor. Descubre
                  cómo las valiosas contribuciones de nuestra comunidad
                  universitaria fortalecen este proyecto y cómo alineamos
                  nuestra misión institucional con las necesidades y
                  expectativas de la sociedad actual.
                </p>{" "}
                <Accordion
                  title="Informe de actividades"
                  content={
                    <>
                      <a
                        target="_blank"
                        href="https://utpl.edu.ec/documentos/pedi/informe1.pdf"
                      >
                        Informe de actividades Nro. 1
                      </a>

                      <a
                        target="_blank"
                        href="https://utpl.edu.ec/documentos/pedi/informe2.pdf"
                      >
                        Informe de actividades Nro. 2
                      </a>

                      <a
                        target="_blank"
                        href="https://utpl.edu.ec/documentos/pedi/informe3.pdf"
                      >
                        Informe de actividades Nro. 3
                      </a>
                      <a
                        target="_blank"
                        href="https://utpl.edu.ec/documentos/pedi/informe4.pdf"
                      >
                        Informe de actividades Nro. 4
                      </a>
                    </>
                  }
                ></Accordion>
              </>
            }
          ></BlockRightImage>
        </div>
      </div>

      <div className="bluebg padding">
        <Block2ColumnsText
          col1={documentToReactComponents(
            data.videoTemplate.contentBlock3.json
          )}
          col2={
            <>
              <Accordion
                title="Documentos POA"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock4.json
                )}
              ></Accordion>
            </>
          }
        ></Block2ColumnsText>
      </div>

      <div className="graybg padding">
        <BlockRightImage
          src={data.videoTemplate.imageBlock5.url}
          alt={data.videoTemplate.imageBlock5.title}
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock5.json)}
            </>
          }
        ></BlockRightImage>
      </div>
      <div className="padding">
        <Block3ColumnsText
          col1={
            <>
              <h2>Acceso a la información (LOTAIP)</h2>
              <p>
                Cumpliendo con el principio de transparencia, presentamos aquí
                el detalle de nuestro cumplimiento de las obligaciones.
              </p>
              <a className="button" href="https://utpl.edu.ec/lotaip">
                Revisa aquí la información
              </a>
            </>
          }
          col2={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock7.json)}

              <Accordion
                title="Documentos"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock8.json
                )}
              ></Accordion>
            </>
          }
          col3={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock9.json)}

              <Accordion
                title="Costos, aranceles, matrículas y derechos"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock10.json
                )}
              ></Accordion>
              <Accordion
                title="Ejecución presupuestaria
                "
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock11.json
                )}
              ></Accordion>
              <Accordion
                title="Estados financieros auditados"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock12.json
                )}
              ></Accordion>
              <Accordion
                title="Remuneraciones"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock13.json
                )}
              ></Accordion>
            </>
          }
        ></Block3ColumnsText>
      </div>
      <div className="graybg padding margin">
        <BlockRightImage
          src={data.videoTemplate.imageBlock6.url}
          alt={data.videoTemplate.imageBlock6.title}
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock6.json)}
              <a
                className="button"
                href="https://utpl.plataformadeintegridad.com/"
              >
                Reportar
              </a>
            </>
          }
        ></BlockRightImage>
      </div>
    </>
  );
}

export default Transparencia;
