import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Select from "react-select";
import TecnologiasVista from "./TecnologiasVista";

function TecnologiasOffer(props) {
  const [dataarea, setDataArea] = useState("");
  const [facultad, setFacultad] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [modalidad, setModalidad] = useState("");

  const updateWindowDimensions = () => {
    const newW = window.innerWidth;
    setWidth(newW);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  function handleChange(e) {
    const { value, checked } = e.target;
    if (checked) {
      setFacultad(value);
    } else {
      setFacultad("0");
    }
  }

  function handleChangeMobile(value) {
    setFacultad(value);
  }

  function handleChangeModalidad(e) {
    const { value, checked } = e.target;

    if (checked) {
      setModalidad(value);
    } else {
      setModalidad(0);
    }
  }

  function handleChangeModalidadMobile(modalidad) {
    setModalidad(modalidad);
  }

  const optionsmodalidad = [
    { value: "a Distancia", label: "a Distancia" },
    { value: "Presencial", label: "Presencial" },
    { value: "En línea", label: "En línea" },
    { value: "0", label: "-" },
  ];

  const optionsfacultad = [
    {
      value: "Ciencias Económicas y Empresariales",
      label: "Ciencias Económicas y Empresariales",
    },

    {
      value: "Ciencias Sociales, Educación y Humanidades",
      label: "Ciencias Sociales, Educación y Humanidades",
    },

    {
      value: "Ingenierías y Arquitectura",
      label: "Ingenierías y Arquitectura",
    },
    { value: "0", label: "-" },
  ];

  return (
    <div className="layout9">
      <div className="">
        {width > 900 && (
          <div>
            {" "}
            {/* Contenedor principal de todo el formulario 
            <div className="layout9 ">
              <p>
                <b>Facultad</b>
              </p>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="0"
                  id="todas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="todas">
                  Todas
                </label>
              </div>

         

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Económicas y Empresariales"
                  id="empresas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="empresas">
                  Ciencias Económicas y Empresariales
                </label>
              </div>
          
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Sociales, Educación y Humanidades"
                  id="sociales"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="sociales">
                  Ciencias Sociales, Educación y Humanidades
                </label>
              </div>
        
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ingenierías y Arquitectura"
                  id="ing"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="ing">
                  Ingenierías y Arquitectura
                </label>
              </div>
              <p>
                <b>Modalidad</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="0"
                  id="modalidad"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="modalidad">
                  Todas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="a Distancia"
                  id="distancia"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="distancia">
                  a Distancia
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="Presencial"
                  id="presencial"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="presencial">
                  Presencial
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="En línea"
                  id="linea"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="linea">
                  En línea
                </label>
              </div>
              <p>
                <b>Área de interés</b>
              </p>

              <div className="wrapper-inputoffer">
                <input
                  className="input-academicoffer"
                  name="area"
                  value={dataarea}
                  onChange={(e) => setDataArea(e.target.value)}
                  placeholder="Ejemplo: empresas"
                ></input>
                <span className="material-icons">search</span>
              </div>
            </div>*/}
          </div>
        )}

        {width < 900 && (
          <div>
            {/*
            <p>
              <b>Facultad</b>
            </p>
            <Select
              options={optionsfacultad}
              defaultValue={facultad}
              onChange={handleChangeMobile}
            ></Select>

            <p>
              <b>Modalidad</b>
            </p>
            <Select
              options={optionsmodalidad}
              defaultValue={modalidad}
              onChange={handleChangeModalidadMobile}
            ></Select>

            <p>
              <b>Área de interés</b>
            </p>
            <div className="wrapper-inputoffer">
              <input
                className="input-academicoffer"
                name="area"
                value={dataarea}
                onChange={(e) => setDataArea(e.target.value)}
                placeholder="Ejemplo: empresas"
              ></input>
              <span className="material-icons">search</span>
            </div> */}
          </div>
        )}

        <div>
          {width > 900 && (
            <TecnologiasVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              modalidad={modalidad}
              facultad={facultad}
            ></TecnologiasVista>
          )}
          {width < 900 && (
            <TecnologiasVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              facultad={facultad.value}
              modalidad={modalidad.value}
            ></TecnologiasVista>
          )}
        </div>
      </div>
    </div>
  );
}

export default TecnologiasOffer;
