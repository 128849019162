import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";
import Notification from "../components/Notification";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";

import ConectUs from "../components/ConectUs";


import TecnologiasOffer from "../components/TecnologiasOffer";
import { DistanciaNuevosFixed } from "../components/Pasos";
import Enlineapagos from "../components/Enlineapagos";

const query = `
query 
{
    videoTemplate(id: "1jjt3x4ctJbJ0VNLYbQ8mS") {
      video{url}
      imageBlock1{url}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      imageBlock4{url}
      imageBlock3{url}
   
    }
}
`;

function Tecnologias() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin relative">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
              <div className="margin">
                <a className="button1" href="#descuentos">
                  <span class="material-icons">chevron_right</span> Accede a
                  descuentos
                </a>
                <a className="button1" href="#becas">
                  <span class="material-icons">chevron_right</span> Postula a
                  una beca
                </a>
              </div>
            </>
          }
          link="https://srv-si-001.utpl.edu.ec/INSCRIPCION_NUEVOS_MAD"
          name_link="Matricúlate ahora"
        ></BlockImageCabecera>
      </div>
      <div className="relative">
        <Notification
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
            </>
          }
          link="https://srv-si-001.utpl.edu.ec/INSCRIPCION_NUEVOS_MAD"
          name_link="Matricúlate ahora"
        ></Notification>
        <DistanciaNuevosFixed></DistanciaNuevosFixed>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/tecnologias">Tecnologias</NavLink>
          </>
        }
      ></Breadcumbs>

      <center>
        <div className="layout8">
          <h2>
            Descubre tu futuro con nuestras <b>carreras tecnológicas</b>
          </h2>
          <p>La carrera que buscabas en el tiempo que esperabas</p>
        </div>
      </center>


    
         <TecnologiasOffer
        title={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
          </>
        }
        subtitle="Elige tu tecnología"
      ></TecnologiasOffer>
     

      <Enlineapagos></Enlineapagos>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes TEC."
        instagram="https://www.instagram.com/utpltec/"
        facebook="https://www.facebook.com/utpltec"
        tiktok="https://www.tiktok.com/@utpltec"
        linkedin="https://www.linkedin.com/company/utpl-tec/"
      ></ConectUs>

      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock3.url}
        content={
          <>
            <div className="icon100 padding2">
              {" "}
              <img src={data.videoTemplate.imageBlock4.url} alt="utpl"></img>
            </div>
            {documentToReactComponents(data.videoTemplate.contentBlock3.json)}
          </>
        }
      ></BlockBackgroundImage>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Tecnologias;
