import { motion } from "framer-motion";
import { useState, useEffect } from "react";

import Select from "react-select";
import PosgradoView from "./PosgradoView";

function PosgradoOffer(props) {
  const [dataarea, setDataArea] = useState("");
  const [facultad, setFacultad] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [tipo_programa, setTipoPrograma] = useState("");
  const [oferta, setOferta] = useState("");
  const [linksprograma, setLinksPrograma] = useState([]);
  const [links, setLinks] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    const newW = window.innerWidth;
    setWidth(newW);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  function handleChange(e) {
    const { value, checked } = e.target;

    if (checked) {
      setFacultad(value);
      setLinks([...links, value]);
    } else {
      const data = links.filter((e) => e !== value);
      setLinks(data);
      setFacultad("0");
    }
  }

  function handleChangeTipoPrograma(e) {
    const { value, checked } = e.target;

    if (checked) {
      setTipoPrograma(value);
      setLinksPrograma([...linksprograma, value]);
    } else {
      const data = linksprograma.filter((e) => e !== value);
      setLinksPrograma(data);

      setTipoPrograma("0");
    }
  }

  function handleChangeTipoProgramaMobile(tipo) {
    setTipoPrograma(tipo);
  }

  function handleChangeModalidad(e) {
    const { value, checked } = e.target;

    if (checked) {
      setModalidad(value);
    } else {
      setModalidad("0");
    }
  }

  function handleChangeModalidadMobile(modalidad) {
    setModalidad(modalidad);
  }

  function handleChangeOferta(e) {
    const { value, checked } = e.target;
    if (checked) {
      setOferta(value);
    } else {
      setOferta("0");
    }
  }

  function handleChangeOfertaMobile(oferta) {
    setOferta(oferta);
  }

  function handleChangeCampoMobile(campo) {
    setFacultad(campo);
  }
  const optionsmodalidad = [
    { value: "Presencial", label: "Presencial" },
    { value: "En línea", label: "En línea" },
    { value: "0", label: "-" },
  ];

  const optionsoferta = [
    { value: "si", label: "Vigente (periodo actual)" },
    { value: "no", label: "Próximo periodo" },
    { value: "0", label: "-" },
  ];

  const optionstipo = [
    { value: "Maestría", label: "Maestría" },
    { value: "Especialidad", label: "Especialidad" },
    { value: "Doctorado", label: "Doctorado" },
    { value: "0", label: "-" },
  ];
  const campoconocimiento = [
    { value: "Administración", label: "Administración" },
    {
      value: "Administración de Empresas y Derecho",
      label: "Administración de Empresas y Derecho",
    },
    {
      value: "Ciencias Naturales, Matemáticas y Estadística",
      label: "Ciencias Naturales, Matemáticas y Estadística",
    },
    {
      value: "Ciencias Sociales, Periodismo e Información",
      label: "Ciencias Sociales, Periodismo e Información",
    },

    {
      value: "Educación",
      label: "Educación",
    },
    {
      value: "Ingeniería, Industria y Construcción",
      label: "Ingeniería, Industria y Construcción",
    },
    {
      value: "Salud y Bienestar",
      label: "Salud y Bienestar",
    },
    {
      value: "Tecnologías de la Información y la Comunicación (TIC)",
      label: "Tecnologías de la Información y la Comunicación (TIC)",
    },
    { value: "0", label: "-" },
  ];
  return (
    <div className="layout9">
      <div className="wrapper-titleoffer ">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          {props.title}
        </motion.div>
      </div>
      <h2>{props.subtitle}</h2>

      <div className="offerbymodelo ">
        {width > 900 && (
          <div>
            <div className="layout9">
              <p>
                <b>Oferta</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="oferta"
                  value="0"
                  id="toda"
                  onChange={handleChangeOferta}
                />
                <label className="form-check-label" htmlFor="toda">
                  Todas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="oferta"
                  value="si"
                  id="si"
                  onChange={handleChangeOferta}
                />
                <label className="form-check-label" htmlFor="si">
                  Vigente
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="oferta"
                  value="no"
                  id="no"
                  onChange={handleChangeOferta}
                />
                <label className="form-check-label" htmlFor="no">
                  Próxima
                </label>
              </div>

              <p>
                <b>Tipo</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="tipo"
                  value="0"
                  id="todass"
                  onChange={handleChangeTipoPrograma}
                />
                <label className="form-check-label" htmlFor="todass">
                  Todas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="tipo"
                  value="maestria"
                  id="maestria"
                  onChange={handleChangeTipoPrograma}
                />
                <label className="form-check-label" htmlFor="maestria">
                  Maestría
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="tipo"
                  value="Especialidad"
                  id="Especialidad"
                  onChange={handleChangeTipoPrograma}
                />
                <label className="form-check-label" htmlFor="Especialidad">
                  Especialidad
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="tipo"
                  value="doctorado"
                  id="doctorado"
                  onChange={handleChangeTipoPrograma}
                />
                <label className="form-check-label" htmlFor="doctorado">
                  Doctorado
                </label>
              </div>

              <p>
                <b>Modalidad</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="0"
                  id="tod"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="tod">
                  Todas
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="Presencial"
                  id="presencial"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="presencial">
                  Presencial
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="En línea"
                  id="linea"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="linea">
                  En línea
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="Híbrida"
                  id="Híbrida"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="Híbrida">
                  Híbrida
                </label>
              </div>

              <p>
                <b>Campo del conocimiento</b>
              </p>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="0"
                  id="todas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="todas">
                  Todas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Administración"
                  id="Administración"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Administración">
                  Administración
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Administración de Empresas y Derecho"
                  id="Administración de Empresas y Derecho"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Administración de Empresas y Derecho"
                >
                  Administración de Empresas y Derecho
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Ciencias Naturales, Matemáticas y Estadística"
                  id="Ciencias Naturales, Matemáticas y Estadística"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Ciencias Naturales, Matemáticas y Estadística"
                >
                  Ciencias Naturales, Matemáticas y Estadística
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Ciencias Sociales, Periodismo e Información"
                  id="Ciencias Sociales, Periodismo e Información"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Ciencias Sociales, Periodismo e Información"
                >
                  Ciencias Sociales, Periodismo e Información / Derecho
                </label>
              </div>
            

         
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Educación"
                  id="Educación"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Educación">
                  Educación
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Ingeniería, Industria y Construcción"
                  id="Ingeniería, Industria y Construcción"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Ingeniería, Industria y Construcción"
                >
                  Ingeniería, Industria y Construcción
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Salud y Bienestar "
                  id="Salud y Bienestar"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Salud y Bienestar">
                  Salud y Bienestar
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Tecnologías de la Información y la Comunicación (TIC)"
                  id="Tecnologías de la Información y la Comunicación (TIC)"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Tecnologías de la Información y la Comunicación (TIC)"
                >
                  Tecnologías de la Información y la Comunicación (TIC)
                </label>
              </div>
              <p>
                <b>Área de interés</b>
              </p>
              <div className="wrapper-inputoffer">
                <input
                  className="input-academicoffer"
                  name="area"
                  value={dataarea}
                  onChange={(e) => setDataArea(e.target.value)}
                  placeholder="Ejemplo: empresas"
                ></input>
                <span className="material-icons">search</span>
              </div>
            </div>
          </div>
        )}

        {width < 900 && (
          <div>
            <p>
              <b>Oferta</b>
            </p>
            <Select
              options={optionsoferta}
              defaultValue={oferta}
              onChange={handleChangeOfertaMobile}
            ></Select>

            <p>
              <b>Tipo</b>
            </p>
            <Select
              options={optionstipo}
              defaultValue={tipo_programa}
              onChange={handleChangeTipoProgramaMobile}
            ></Select>
            <p>
              <b>Modalidad</b>
            </p>
            <Select
              options={optionsmodalidad}
              defaultValue={modalidad}
              onChange={handleChangeModalidadMobile}
            ></Select>

            <p>
              <b>Campo del conocimiento</b>
            </p>
            <Select
              options={campoconocimiento}
              defaultValue={facultad}
              onChange={handleChangeCampoMobile}
            ></Select>
            <p>
              <b>Área de interés</b>
            </p>
            <div className="wrapper-inputoffer">
              <input
                className="input-academicoffer"
                name="area"
                value={dataarea}
                onChange={(e) => setDataArea(e.target.value)}
                placeholder="Ejemplo: empresas"
              ></input>
              <span className="material-icons">search</span>
            </div>
          </div>
        )}
        <div>
          {width > 900 && (
            <PosgradoView
              keyword={dataarea.trimEnd().toLowerCase()}
              modalidad={modalidad}
              facultad={facultad}
              oferta={oferta}
              programa={props.programa}
              tipo_programa={tipo_programa}
            ></PosgradoView>
          )}
          {width < 900 && (
            <PosgradoView
              keyword={dataarea.trimEnd().toLowerCase()}
              modalidad={modalidad.value}
              facultad={facultad.value}
              oferta={oferta.value}
              programa={props.programa}
              tipo_programa={tipo_programa.value}
            ></PosgradoView>
          )}
        </div>
      </div>
    </div>
  );
}

export default PosgradoOffer;
